import React from 'react';
import { classList } from '~/utilities/css';

type AuthLayoutProps = {
	children: React.ReactNode;
};

function AuthLayout({ children }: AuthLayoutProps) {
	return (
		<div
			className={classList([
				'uic-flex uic-flex-col uic-gap-20',
				'uic-shadow uic-rounded-lg',
				'uic-p-20 uic-w-320 uic-max-h-[400px] uic-overflow-scroll',
				'uic-relative uic-bg-white',
			])}
		>
			{children}
		</div>
	);
}

export { AuthLayout as default, AuthLayout };
